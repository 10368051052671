import React from 'react';
import { Select } from 'antd';
import * as i18next from 'i18next';
import {IFilters, IGameItemData} from 'helpers/interfaces';
import {OperatorGamesUtils} from 'utils';

const { Option } = Select;

interface IProps {
    t: i18next.TFunction;
    onChangeCallback: Function;
    filters: IFilters;
}

interface IState {
    open: boolean;
}

export class GamesSelector extends React.Component<IProps, IState> {
    select: any = React.createRef();

    state: IState = {
        open: false,
    };

    handleChange = (games: string[]) => {
        this.props.onChangeCallback('games', games);
        this.select.current.blur();
        this.setState({ open: false });
    };

    getGamesOptions = () =>
        OperatorGamesUtils.getGames().map((game: IGameItemData) => {
            const gameData = game.name + ` (id: ${game.id})`;

            return (
                <Option key={gameData} value={gameData}>
                    {gameData}
                </Option>
            );
        });

    handleFocus = () => this.setState({ open: true });

    handleBlur = () => this.setState({ open: false });

    render = () => {
        const { t, filters } = this.props;

        return (
            <div className="filter-item">
                <div className="filter-title">{t('games')}</div>
                <div className="filter-content games">
                    <Select
                        defaultValue={filters.games}
                        ref={this.select}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        open={this.state.open}
                        bordered={false}
                        className="games-selector"
                        showSearch
                        placeholder={t('select_games')}
                        mode="multiple"
                        onChange={this.handleChange}
                        notFoundContent={t('no_data_search')}
                    >
                        {this.getGamesOptions()}
                    </Select>
                </div>
            </div>
        );
    };
}
