import * as React from 'react';
import '../ChartStyles.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import ReactEcharts from 'echarts-for-react';
import { ChartConstants, chartKey } from '../ChartConstants';
import { currencyUtils, OperatorGamesUtils } from 'utils';
import { Select } from 'antd';
import ItemComponentWrapper from 'Components/ContentComponent/ItemComponentWrapper';
import { CloseOutlined, EllipsisOutlined } from '@ant-design/icons/lib';
import { Overlay } from '../../../Components/Overlay/Overlay';

const { Option } = Select;

interface IProps {
    data: any[];
}

interface IState {
    activeChart: chartKey;
    sortedGamesData: any[];
    gamesAmount: number;
    chosenGameId: number;
    chosenGamesData: any[];
    isOverlayVisible: boolean;
    isMenuVisible: boolean;
}

interface ICustomSeriesStyles {
    color: string;
}

interface ICustomSeries {
    value: number;
    itemStyle?: ICustomSeriesStyles;
}

const defaultActiveChart: chartKey = 'bet';
const defaultAmountOfGames = 10;
const gamesAmountObj: any = {
    10: 10,
    15: 15,
    20: 20,
};
const charts: chartKey[] = ['bet', 'win', 'net', 'spins', 'rtp', 'players'];
const financialCharts: chartKey[] = ['bet', 'win', 'net'];
const barColor = '#CF0920';

class GamesBarChart extends React.Component<IProps & WithTranslation, IState> {
    state: IState = {
        activeChart: defaultActiveChart,
        chosenGameId: -1,
        gamesAmount: defaultAmountOfGames,
        isOverlayVisible: false,
        isMenuVisible: false,
        ...GamesBarChart.getGamesData(this.props.data),
    };

    echarts_react: any = React.createRef();

    static getGamesData = (
        data: any[],
        key: chartKey = defaultActiveChart,
        gamesAmount: number = defaultAmountOfGames,
    ) => {
        const sortedGamesData = [...data].sort(
            (a: any, b: any) => b[key] - a[key],
        );
        const chosenGamesData = sortedGamesData;

        chosenGamesData.length =
            gamesAmountObj[gamesAmount] || sortedGamesData.length;

        return {
            sortedGamesData,
            chosenGamesData,
        };
    };

    getOptions = () =>
        this.props.data && {
            color: barColor,
            xAxis: this.getXAxis(),
            yAxis: this.getYAxis(),
            series: this.getSeries(),
            ...ChartConstants.commonOptions,
            tooltip: {
                ...ChartConstants.tooltipCommonOptions,
                ...ChartConstants.tooltipBrokenDownDataOptions(
                    this.state.activeChart,
                ),
                trigger: 'item',
            },
        };

    getXAxis = () => {
        const data: string[] = [];
        const axisLabel = {
            margin: 20,
            rotate: 90,
            fontSize: 9,
        };

        this.state.chosenGamesData.map((gameData: any) =>
            data.push(OperatorGamesUtils.getGameNameByID(gameData.gameId)),
        );

        return [{ data, ...ChartConstants.commonAxisXStyles, axisLabel }];
    };

    getYAxis = () => {
        const { activeChart } = this.state;
        const name = financialCharts.includes(activeChart)
            ? currencyUtils.getActiveCurrencyCode()
            : activeChart === 'rtp'
            ? '%'
            : '';

        return [{ name, ...ChartConstants.commonAxisYStyles }];
    };

    getSeries = () => {
        const { chosenGamesData, activeChart } = this.state;
        const seriesData: ICustomSeries[] = [];
        const { subunit } = currencyUtils.getActiveCurrencyData();
        const isMoneyChart = financialCharts.includes(activeChart);

        chosenGamesData.forEach((gameData: any) => {
            const formattedValue = isMoneyChart
                ? gameData[activeChart] / subunit
                : gameData[activeChart];

            seriesData.push({ value: formattedValue });
        });

        return [
            {
                name: this.props.t(activeChart),
                type: 'bar',
                data: seriesData,
            },
        ];
    };

    getChartSelectors = () => (
        <Select
            className="selectors"
            defaultValue={this.state.activeChart || defaultActiveChart}
            onChange={this.onChartSelectChange}
        >
            {charts.map((key: chartKey) => (
                <Option key={key} value={key}>
                    {this.props.t(key)}
                </Option>
            ))}
        </Select>
    );

    onChartSelectChange = (value: chartKey) => {
        this.setState((state) => ({
            activeChart: value,
            ...GamesBarChart.getGamesData(
                this.props.data,
                value,
                state.gamesAmount,
            ),
        }));
    };

    getMenu = () => {
        const { data, t } = this.props;
        const { gamesAmount, isMenuVisible } = this.state;
        const items = Object.keys(gamesAmountObj).map(
            (key: string) => (key += ` ${t('games').toLowerCase()}`),
        );
        const menuItems: string[] = [...items, t('all_games')];

        return (
            <div className="menu" onClick={this.onMenuClick}>
                <EllipsisOutlined />
                {isMenuVisible && (
                    <div className="menu-popup">
                        <div className="menu-title">{t('games_per_chart')}</div>
                        <div className="menu-items">
                            {menuItems.map((item: string) => {
                                const amount = parseInt(item, 10);
                                const isActive = amount === gamesAmount;
                                const isDisabled =
                                    gamesAmountObj[item] > data.length;
                                const className =
                                    (isActive ? 'active' : '') +
                                    (isDisabled ? ' disabled' : '');

                                return (
                                    <div
                                        key={item}
                                        className={className}
                                        onClick={() =>
                                            this.changeGamesAmount(amount)
                                        }
                                    >
                                        {item}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    changeGamesAmount = (value: number) => {
        this.setState((state) => ({
            gamesAmount: value,
            ...GamesBarChart.getGamesData(
                this.props.data,
                state.activeChart,
                value,
            ),
        }));
    };

    onMenuClick = () =>
        this.setState((state) => ({ isMenuVisible: !state.isMenuVisible }));

    getGameDataPopup = () => {
        const { t } = this.props;
        const { chosenGamesData, chosenGameId } = this.state;
        const currencyCode = currencyUtils.getActiveCurrencyData().code;

        if (chosenGameId > 0) {
            const {
                spins,
                players,
                net,
                bet,
                win,
                rtp,
                gameId,
                fromDate,
                toDate,
            } = chosenGamesData.filter(
                (gameData: any) => chosenGameId === gameData.gameId,
            )[0];

            return (
                <>
                    {/*<div className='overlay visible' onClick={this.closeGameDataPopup} />*/}
                    <div className="game-data-popup">
                        <CloseOutlined onClick={this.closeGameDataPopup} />
                        <div className="title">
                            {OperatorGamesUtils.getGameNameByID(gameId)}
                        </div>
                        <div className="dates">{`${fromDate} - ${toDate}`}</div>
                        <div className="items">
                            <div className="row">
                                <div className="item">
                                    <div className="item-heading">
                                        {t('spins')}
                                    </div>
                                    <div className="item-data">{spins}</div>
                                </div>
                                <div className="item">
                                    <div className="item-heading">
                                        {t('net')}
                                    </div>
                                    <div className="item-data">
                                        {net}
                                        <span> {currencyCode}</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-heading">
                                        {t('win')}
                                    </div>
                                    <div className="item-data">
                                        {win}
                                        <span> {currencyCode}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="item">
                                    <div className="item-heading">
                                        {t('bet')}
                                    </div>
                                    <div className="item-data">
                                        {bet}
                                        <span> {currencyCode}</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-heading">
                                        {t('rtp')}
                                    </div>
                                    <div className="item-data">
                                        {rtp}
                                        <span>%</span>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="item-heading">
                                        {t('players')}
                                    </div>
                                    <div className="item-data">{players}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    };

    closeGameDataPopup = () =>
        this.setState({ chosenGameId: -1, isOverlayVisible: false });

    showGameDataPopup = (params: any) => {
        this.setState({
            chosenGameId: OperatorGamesUtils.getGameIdByName(params.name),
            isOverlayVisible: true,
        });
    };

    componentDidMount(): void {
        const myChart = this.echarts_react.getEchartsInstance();

        myChart.on('click', (params: any) => this.showGameDataPopup(params));

        window.addEventListener('click', this.handleRandomClick, true);
    }

    componentWillUnmount = () =>
        window.removeEventListener('click', this.handleRandomClick);

    handleRandomClick = (e: Event) => {
        if (
            this.state.isMenuVisible &&
            !(e.target as Element).closest('.menu-popup')
        ) {
            this.setState({ isMenuVisible: false });
        }
    };

    render = () => {
        const { t } = this.props;
        const { isOverlayVisible } = this.state;

        return (
            <>
                <ItemComponentWrapper titleKey={'game_comparison'} collapsible>
                    <div className="chart barChart">
                        {this.getChartSelectors()}
                        {this.getMenu()}
                        <div className="axis-names">
                            <div>{t(this.state.activeChart)}</div>
                        </div>
                        <ReactEcharts
                            ref={(elem) => (this.echarts_react = elem)}
                            notMerge
                            option={this.getOptions()}
                        />
                        {this.getGameDataPopup()}
                    </div>
                </ItemComponentWrapper>
                <Overlay
                    isVisible={isOverlayVisible}
                    switchState={this.closeGameDataPopup}
                />
            </>
        );
    };
}

export default withTranslation()(GamesBarChart);
