import { useTranslation } from 'react-i18next';
import ItemComponentWrapper from '../../../Components/ContentComponent/ItemComponentWrapper';
import NewPromotionModal from '../PromotionModalForm/PromotionModal';
import {
    CPUtils,
    OperatorGamesUtils,
    currencyUtils,
    localStorageHelper,
    dataFormatterUtils,
} from '../../../utils';
import React, { useMemo } from 'react';
import { ICountry, IPromotionData } from '../../../helpers/interfaces';

interface IPromotionInfoTableProps {
    data: IPromotionData;
}

const PromotionInfoTable = ({ data }: IPromotionInfoTableProps) => {
    const { t } = useTranslation();
    const countriesList = useMemo(() => localStorageHelper.getChainedValue('user.countries'), []);
    const countriesNames = countriesList
        .filter((c: ICountry) => data.countries.includes(c.alpha2Code))
        .map((c: ICountry) => c.name);
    const gamesList = useMemo(() => OperatorGamesUtils.getGames(), []);
    const gamesListNames = useMemo(() => CPUtils.gameIdToNames(data.gameIds), [data.gameIds]);
    const isUserCanManageCampaigns = localStorageHelper
        .getChainedValue('user.authorities')
        .includes('MANAGE_CAMPAIGNS');
    const getBetList = () => {
        const { betLevel, betGames } = data;
        const getGameName = (id: number) =>
            gamesList.filter((game: any) => {
                return game.id === id;
            })[0].name;

        return (
            <>
                <div className="promotion-table-item">
                    <div className="promotion-table-item__label">Bet Level:</div>
                    <div className="promotion-table-item__value">{betLevel}</div>
                </div>
                <div className="promotion-table-item">
                    <div className="promotion-table-item__label">Games & Bets:</div>
                    <div className="promotion-table-item__value">
                        {betLevel &&
                            betGames.map((game: any) => (
                                <p
                                    key={game.gameId}
                                >{`${getGameName(game.gameId)} (${dataFormatterUtils.getFormattedData(game, false, data.currencyCode).bet} ${data.currencyCode})`}</p>
                            ))}
                    </div>
                </div>
            </>
        );
    };

    return (
        <ItemComponentWrapper>
            {data && data.status === 'UPCOMING' && isUserCanManageCampaigns && (
                <div className="promotion__edit-button-wrapper">
                    <NewPromotionModal initialData={data} />
                </div>
            )}
            <div className="promotion-table">
                <div className="promotion-table__side">
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('name') + ': '}</div>
                        <div className="promotion-table-item__value">{data.name}</div>
                    </div>
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('reference') + ': '}</div>
                        <div className="promotion-table-item__value">{data.externalCampaignId}</div>
                    </div>
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('start_at') + ': '}</div>
                        <div className="promotion-table-item__value">{data.startDate}</div>
                    </div>
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('end_at') + ': '}</div>
                        <div className="promotion-table-item__value">{data.endDate}</div>
                    </div>
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('status') + ': '}</div>
                        <div className="promotion-table-item__value">{data.status}</div>
                    </div>
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('strategy') + ': '}</div>
                        <div className="promotion-table-item__value">{data.strategy}</div>
                    </div>
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('timeZone') + ': '}</div>
                        <div className="promotion-table-item__value">{data.timezone}</div>
                    </div>
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">{t('countries') + ': '}</div>
                        <div
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                            className="promotion-table-item__value"
                        >
                            {countriesNames.length === countriesList.length
                                ? t('all_countries')
                                : countriesNames.map((country: string, i: number) => (
                                      <p key={country}>{`${country}${
                                          i < countriesNames.length - 1 ? ', ' : ''
                                      }`}</p>
                                  ))}
                        </div>
                    </div>
                </div>
                <div className="promotion-table__side">
                    {data.betLevel ? (
                        getBetList()
                    ) : (
                        <>
                            <div className="promotion-table-item">
                                <div className="promotion-table-item__label">
                                    {t('gameName') + ': '}
                                </div>
                                <div className="promotion-table-item__value">
                                    {gamesListNames?.length === gamesList?.length
                                        ? t('all_games')
                                        : gamesListNames.map((game: string, i: number) => (
                                              <p key={game}>{`${game}${
                                                  i < gamesListNames?.length - 1 ? ', ' : ''
                                              }`}</p>
                                          ))}
                                </div>
                            </div>
                            <div className="promotion-table-item">
                                <div className="promotion-table-item__label">{t('bet') + ': '}</div>
                                <div className="promotion-table-item__value">
                                    {Number(data.bet) /
                                        currencyUtils.getCurrencyDataByCode(data.currencyCode)
                                            .subunit +
                                        ' ' +
                                        data.currencyCode}
                                </div>
                            </div>
                        </>
                    )}
                    <div className="promotion-table-item">
                        <div className="promotion-table-item__label">
                            {t('amount_of_bets') + ': '}
                        </div>
                        <div
                            className="promotion-table-item__value"
                            style={{ display: 'flex', alignItems: 'flex-end' }}
                        >
                            {data.betNumber}
                        </div>
                    </div>
                </div>
            </div>
        </ItemComponentWrapper>
    );
};

export default PromotionInfoTable;
