import { localStorageHelper, Messages } from 'utils';
import { errorHandler } from 'helpers/errorHandler';
import { IGameItemData, IAction } from 'helpers/interfaces';
import { GamesTypes } from 'redux/actions/configProvider/games-actions';
import i18next from 'i18next';

export interface IAddNewGameState {
    adding?: boolean;
    currentGameDefaultConfig?: any[];
    isDefaultConfigUpdated?: any;
    isGameConfigUpdated?: any;
}

const DEFAULT_GAME_TYPE = 'SLOT';

const gamesReducer = (state: IAddNewGameState = {}, actions: IAction) => {
    switch (actions.type) {
        case GamesTypes.ADD_GAME_REQUEST:
        case GamesTypes.ADD_GAME_DEFAULT_CONFIG_REQUEST: {
            return { adding: true };
        }

        case GamesTypes.ADD_GAME_FAILED:
        case GamesTypes.ADD_GAME_DEFAULT_CONFIG_FAILED:
        case GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_FAILED: {
            Messages.error(errorHandler.getErrorText(actions.payload));

            return {};
        }

        case GamesTypes.ADD_GAME_DEFAULT_CONFIG_SUCCESS: {
            Messages.success(i18next.t('new_game_successfully_added'));

            return { isGameConfigUpdated: true };
        }

        case GamesTypes.ADD_GAME_SUCCESS: {
            const { id, name, freeRoundsSupported, buyFeatureSupported, gameType = DEFAULT_GAME_TYPE } = actions.payload;
            const allGames: IGameItemData[] = localStorageHelper.getChainedValue('user.allGames');

            if (allGames) {
                allGames.push({id: +id, name, freeRoundsSupported, buyFeatureSupported, gameType});
                allGames.sort((a: IGameItemData, b: IGameItemData) =>
                    a.id < b.id ? 1 : 0,
                );
            }

            localStorageHelper.setChainedValue('user.allGames', allGames);

            return {};
        }

        case GamesTypes.GET_DEFAULT_GAME_CONFIGURATIONS_SUCCESS: {
            return { currentGameDefaultConfig: actions.payload };
        }

        case GamesTypes.UPDATE_DEFAULT_GAME_CONFIGURATIONS_SUCCESS: {
            Messages.success(i18next.t('game_config_successfully_updated'));

            return { isDefaultConfigUpdated: true };
        }

        case GamesTypes.UPDATE_DEFAULT_GAME_CONFIGURATIONS_FAILED: {
            Messages.error(i18next.t('game_config_was_not_updated'));

            return {};
        }

        default:
            return state;
    }
};

export default gamesReducer;
