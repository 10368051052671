const BACK_OFFICE_COMMON_RESOURCES = [
    'GET_ALL_CURRENCIES_REQUEST',
    'GET_ALL_CURRENCIES_BY_BRAND_REQUEST',
    'GAMES_BY_OPERATOR_REQUEST',
    'TIME_ZONE_REQUEST',
];
const CONFIGURATION_PROVIDER_COMMON_RESOURCES = ['GET_ALL_CURRENCIES_REQUEST'];

export const AUTHORITIES_MAP = [
    //Allows to download a ZIP aggregated CSV report for all our operators into one file
    {
        name: 'REPORT_MONTHLY_BILLING',
        application: 'MBR',
        resources: [...BACK_OFFICE_COMMON_RESOURCES, 'GET_ALL_CURRENCIES_REQUEST'],
    },
    //Allows creation of a new user account for the brand
    {
        name: 'CONFIGURE_INTEGRATION',
        application: 'CP',
        resources: [
            ...CONFIGURATION_PROVIDER_COMMON_RESOURCES,
            // 'GET_DEFAULT_GAME_CONFIGS_REQUEST',
            'GET_ALL_GAMES_REQUEST',
        ],
    },
    //Allows clearance of stuck rounds and management of rounds
    {
        name: 'SESSION_MANAGEMENT',
        application: 'CP',
        resources: [...CONFIGURATION_PROVIDER_COMMON_RESOURCES],
    },
    //Displays Casino Performance Report
    {
        name: 'REPORT_REVENUE',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    //Displays Players Performance Report
    {
        name: 'REPORT_PLAYER_GROUP_REVENUE',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    //Displays Performance Report for a given player
    {
        name: 'REPORT_REVENUE',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    //Displays Game Performance Report
    {
        name: 'REPORT_GAME_REVENUE',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    //Displays Player Game History Report
    {
        name: 'REPORT_HISTORY',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    {
        name: 'REPORT_HISTORY',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    {
        name: 'MANAGE_CAMPAIGNS',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES, 'GET_ALL_COUNTRIES_REQUEST'],
    },
    {
        name: 'VIEW_CAMPAIGNS',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES, 'GET_ALL_COUNTRIES_REQUEST'],
    },
    //Allows to search players by login
    {
        name: 'VIEW_ACCOUNT_INFO',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    //Allows to change the name of current account
    {
        name: 'CHANGE_NAME',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    //Allows to change the password of current account
    {
        name: 'CHANGE_PASSWORD',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
    //Allows to change the account preferences of current account
    {
        name: 'CHANGE_PREFERENCES',
        application: 'BO',
        resources: [...BACK_OFFICE_COMMON_RESOURCES],
    },
];
