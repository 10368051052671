import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../commonStyles.scss';
import { IPlayersData } from 'redux/reducers/backoffice/players-report-reducer';
import { getPlayersReport } from 'redux/actions/backoffice/players-report-actions';
import {
    getPlayers,
    getPlayersTotalItems,
    getPlayersTotalLine,
    isPlayersReportLoading,
} from 'redux/selectors/backoffice/players-report-selectors';
import { useTranslation } from 'react-i18next';
import { currencyUtils, dateFormatter, localStorageHelper } from '../../../utils';
import { useCallback, useEffect, useState } from 'react';
import { ContentComponent } from '../../../Components/ContentComponent/ContentComponent';
import { Link } from 'react-router-dom';
import { Context } from '../../../App';
import TableHeaderWrapper from '../../../Components/Table/TableHeaderWrapper';
import { HeaderTitleComponent } from '../../../Components/ContentComponent/HeaderTitleComponent';
import TotalLineComponent from '../../../Components/TotalLineComponent/TotalLineComponent';
import { Button, Checkbox, Divider, Modal, Radio, Table } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { PlayersColumns } from './PlayersColumns';
import { getDateOfLastRefresh } from '../../../redux/selectors/user-selectors';
import type { GetProp, TableProps } from 'antd';
import type { SorterResult } from 'antd/es/table/interface';
import { playersReportData } from '../columnsData';

type TablePaginationConfig = Exclude<GetProp<TableProps, 'pagination'>, boolean>;

interface TableParams {
    pagination: TablePaginationConfig;
    sortField?: SorterResult<any>['field'];
    sortOrder?: SorterResult<any>['order'];
    filters?: Parameters<GetProp<TableProps, 'onChange'>>[1];
}

const PlayersReportComponent: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const data: IPlayersData[] = useSelector(getPlayers);
    const isLoading: boolean = useSelector(isPlayersReportLoading);
    const totalLine: any = useSelector(getPlayersTotalLine);
    const totalItems: any = useSelector(getPlayersTotalItems);
    const dateOfLastRefresh: Date = useSelector(getDateOfLastRefresh);
    const activeCurrencyCode = currencyUtils.getActiveCurrencyCode();
    const columns = PlayersColumns(activeCurrencyCode);
    const options = columns.map(({ key }) => ({
        label: t(key),
        value: key,
    }));
    const initialDates = localStorageHelper.getChainedValue('user.reports.dates');

    const [tableSize, setTableSize] = useState<'small' | 'middle' | 'large'>('middle');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableHeight, setTableHeight] = useState(0);
    const [tableParams, setTableParams] = useState<TableParams>({
        sortField: 'login',
        sortOrder: 'ascend',
        pagination: {
            current: 1,
            pageSize: 20,
        },
    });
    const [checkedList, setCheckedList] = useState(columns.map((item) => item.key));
    const [fromDate, setFromDate] = useState<Date>(
        initialDates?.fromDate || dateFormatter.getDefaultDate(),
    );
    const [toDate, setToDate] = useState<Date>(
        initialDates?.toDate || dateFormatter.getDefaultDate(false),
    );
    const visibleColumns = columns.filter((column) => checkedList.includes(column.key));
    const totalWidth = visibleColumns.reduce((total, col) => total + (col.width || 0), 0);

    const calculateTableHeight = useCallback(() => {
        const height = window.innerHeight * 0.8 - 194;
        setTableHeight(height);
    }, []);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const getReportData = useCallback(() => {
        dispatch(getPlayersReport({ fromDate, toDate, tableParams }));
    }, [fromDate, toDate, tableParams, dispatch]);

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter: any) => {
        setTableParams({
            pagination,
            sortOrder: sorter.order ? sorter.order : tableParams.sortOrder,
            sortField: sorter.field ? sorter.field : tableParams.sortField,
        });
    };

    const onShowSizeChange = (current: number, pageSize: number) => {
        setTableParams({
            ...tableParams,
            pagination: {
                current,
                pageSize,
            },
        });
    };

    const parentReportCallback = (dates: { fromDate: Date; toDate: Date }) => {
        setFromDate(dates.fromDate);
        setToDate(dates.toDate);
    };

    const reportData = data ? (data.length ? data : []) : null;

    useEffect(() => {
        getReportData();
    }, [getReportData, fromDate, toDate, tableParams]);

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, [calculateTableHeight]);

    return (
        <ContentComponent
            customBreadcrumbs={<Link to={`/casino/reports`}>{t('reports')}</Link>}
            header={
                <Context.Consumer>
                    {(context) => (
                        <TableHeaderWrapper
                            titleComponent={
                                <HeaderTitleComponent title={t('players_performance')} />
                            }
                            parentReportCallback={parentReportCallback}
                            getReport={getReportData}
                            fromDate={fromDate}
                            toDate={toDate}
                            reportKey="players"
                            context={context}
                            dateOfLastRefresh={dateOfLastRefresh}
                        />
                    )}
                </Context.Consumer>
            }
            preHeader={<TotalLineComponent data={totalLine} columns={playersReportData} />}
            innerContent={
                <>
                    <div className={'casino_performance-table-title'}>
                        <div className="content-wrapper">
                            <>
                                <Button style={{ marginRight: '8px' }} onClick={toggleModal}>
                                    <SettingOutlined />
                                </Button>
                            </>
                        </div>
                        <Modal
                            title="Table tools"
                            open={isModalVisible}
                            onOk={toggleModal}
                            onCancel={toggleModal}
                        >
                            <Divider>Columns displayed</Divider>
                            <Checkbox.Group
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    marginBottom: '20px',
                                }}
                                options={options}
                                value={checkedList}
                                onChange={(checkedValues) => {
                                    setCheckedList(checkedValues.map(String));
                                }}
                            />
                            <Divider>Columns displayed</Divider>
                            <div style={{ marginBottom: 16 }}>
                                <span>Change Table Cell Size: </span>
                                <Radio.Group
                                    value={tableSize}
                                    onChange={(e) => setTableSize(e.target.value)}
                                >
                                    <Radio.Button value="large">Large</Radio.Button>
                                    <Radio.Button value="middle">Middle</Radio.Button>
                                    <Radio.Button value="small">Small</Radio.Button>
                                </Radio.Group>
                            </div>
                        </Modal>
                    </div>
                    <div className="casino_performance-table-container">
                        <Table
                            style={{
                                minWidth: '100%',
                                paddingBottom: '16px',
                            }}
                            className={'casino_performance-table'}
                            dataSource={reportData || []}
                            sortDirections={['descend', 'ascend', 'descend']}
                            scroll={{ x: totalWidth, y: tableHeight }}
                            columns={visibleColumns}
                            loading={isLoading}
                            pagination={{
                                current: tableParams.pagination.current,
                                pageSize: tableParams.pagination.pageSize,
                                total: totalItems,
                                pageSizeOptions: [10, 20, 50, 100],
                                showSizeChanger: true,
                                onShowSizeChange: onShowSizeChange,
                                position: ['topRight'],
                            }}
                            onChange={handleTableChange}
                            size={tableSize}
                            rowKey={(record) =>
                                `${record.fromDate.toString()}-${record.toDate.toString()}-${record.playerId.toString()}-${record.currencyId.toString()}`
                            }
                            footer={() => <></>}
                        />
                    </div>
                </>
            }
        />
    );
};

export default PlayersReportComponent;
