import { ICurrencyData, IGameItemData, IOperatorsData, ISortData } from 'helpers/interfaces';
import { CONSTANTS } from 'utils/config';
import { message, Tooltip } from 'antd';
import * as React from 'react';
import { useMediaQuery } from 'react-responsive';
import dayjs from 'dayjs';
import * as LZString from 'lz-string';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

const CONFIG_PROVIDER_ROLES = ['CONFIGURE_INTEGRATION', 'SESSION_MANAGEMENT'];
const MIN_DESKTOP_WIDTH = 1025;
const MAX_TABLET_WIDTH = 1024;
const MIN_TABLET_WIDTH = 768;
const MAX_MOBILE_WIDTH = 767;
const MAX_LANDSCAPED_MOBILE_WIDTH = 812;

export enum FreeRoundType {
    PRIMARY_FLOW,
    SECONDARY_FLOW,
}

export const localStorageHelper = {
    local: window.localStorage, // storage to be used

    set: (key: string, value: any) => {
        localStorageHelper.local.setItem(key, JSON.stringify(value));

        return localStorageHelper;
    },

    get: (key: string) => localStorageHelper.local.getItem(key),

    getParsed: (key: string) => {
        const storedData = localStorageHelper.local.getItem(key);

        return storedData && JSON.parse(storedData);
    },

    getChainedValue: (key: string) => {
        const keys: string[] = key.split('.');
        const storedData = localStorageHelper.local.getItem(keys[0]);
        let data: any = null;

        if (storedData) {
            data = JSON.parse(storedData);

            keys.forEach((key, index) => {
                if (index && data) {
                    data = data[key];
                }
            });
        }

        return data;
    },

    setChainedValue: (key: string, value: any) => {
        const keys: string[] = key.split('.');
        const storedData = localStorageHelper.local.getItem(keys[0]);
        const data = storedData ? JSON.parse(storedData) : {};
        let currentLink = data;

        keys.forEach((key, index) => {
            if (index) {
                if (storedData && currentLink[key]) {
                    if (index === keys.length - 1) {
                        currentLink[key] = value;
                    } else {
                        currentLink = currentLink[key];
                    }
                } else {
                    if (index === keys.length - 1) {
                        currentLink[key] = value;
                    } else {
                        currentLink[key] = {};
                        currentLink = currentLink[key];
                    }
                }
            }
        });

        localStorageHelper.local.setItem(keys[0], JSON.stringify(data));

        return localStorageHelper;
    },

    remove: (...keys: any[]) => {
        for (const key of keys) {
            localStorageHelper.local.removeItem(key);
        }
    },

    clear: () => localStorageHelper.local.clear(),
};

export const dateFormatter = {
    getFormattedStartDate: (date: Date) => dayjs(date).startOf('d').format().slice(0, 19),

    getFormattedEndDate: (date: Date) => dayjs(date).add(1, 'd').startOf('d').format().slice(0, 19),

    getFormattedStartDateWithHours: (date: Date) => dayjs(date).startOf('h').format().slice(0, 19),

    getFormattedEndDateWithHours: (date: Date) => {
        if (dateFormatter.isFuture(date)) {
            return dayjs(date).startOf('h').format().slice(0, 19);
        } else {
            return dateFormatter.getFormattedEndDate(date);
        }
    },

    getFormattedDateOfLastRefresh: (date: Date) => {
        const currentTimeZone = localStorageHelper.getChainedValue('user.preferences.timeZone');
        dayjs.extend(relativeTime);
        dayjs.extend(utc);
        dayjs.extend(timezone);

        const updateDateInTimeZone = dayjs.tz(date, currentTimeZone);
        const nowInTimeZone = dayjs().tz(currentTimeZone);

        return updateDateInTimeZone.from(nowInTimeZone);
    },

    getDefaultDate: (startOfDay = true) => {
        const amount = 1;
        const tempDate = dayjs();

        return new Date(
            (startOfDay ? tempDate.subtract(amount, 'd').startOf('d') : tempDate).format(),
        );
    },

    getFormattedForReportDate: (date: Date) => dayjs(date).format(CONSTANTS.dateFormat),

    isFuture: (date: Date) => dayjs().isAfter(dayjs(date)),
};

export const currencyUtils = {
    getActiveCurrencyCode: () =>
        localStorageHelper.getChainedValue('user.preferences.currency') || 'CNY',

    getActiveCurrencyData: () => {
        const currencyList = localStorageHelper.getChainedValue('user.allCurrencies');
        const activeCurrency = currencyUtils.getActiveCurrencyCode();

        if (currencyList && activeCurrency) {
            return currencyList.filter((elem: any) => elem.code === activeCurrency)[0];
        }
    },

    getCurrencyDataById: (id: number) => {
        const allCurrencies = localStorageHelper.getChainedValue('user.allCurrencies');

        return allCurrencies.filter((elem: any) => elem.id === +id)[0];
    },

    getCurrencyDataByCode: (code: string) => {
        const allCurrencies = localStorageHelper.getChainedValue('user.allCurrencies');

        return allCurrencies.filter((elem: any) => elem.code === code)[0];
    },

    getCurrencyDataByName: (name: string) => {
        const allCurrencies = localStorageHelper.getChainedValue('user.allCurrencies');

        return allCurrencies.filter((elem: any) => elem.name === name)[0];
    },

    getFormattedCurrency: (value: number, subunit: number) =>
        new Intl.NumberFormat('en', {
            minimumFractionDigits: subunit.toString().length - 1,
        }).format(value / subunit),

    getFormattedCurrencyWithComas: (value: string | number, subunit: number) => {
        let newValue: number;

        if (typeof value === 'string') {
            newValue = Number(value.replace(',', '').replace('.', ''));
        } else {
            newValue = value;
        }

        return currencyUtils.getFormattedCurrency(newValue, subunit);
    },

    getSelectedCurrenciesIdsData: () => {
        const activeCurrency = currencyUtils.getActiveCurrencyData();
        const isActiveCurrencyBillable = activeCurrency.billable;

        if (!isActiveCurrencyBillable) {
            return [activeCurrency.id];
        }

        const currenciesIds: number[] = [];
        const selectedCurrenciesNames: string[] = localStorageHelper.getChainedValue(
            'user.reports.filters.currencies',
        );
        const currenciesByBrand: ICurrencyData[] =
            localStorageHelper.getChainedValue('user.currenciesByBrand');

        selectedCurrenciesNames &&
            selectedCurrenciesNames.forEach((code: string) => {
                currenciesByBrand.forEach((currencyData: ICurrencyData) => {
                    currencyData.code === code && currenciesIds.push(currencyData.id);
                });
            });

        return currenciesIds.length ? currenciesIds : null;
    },

    sortCurrencyByCode: (currencies: string[]) => {
        const allCurrencies = localStorageHelper.getChainedValue('user.allCurrencies');
        const compare_code = (a: any, b: any) => {
            if (a.code < b.code) {
                return -1;
            } else if (a.code > b.code) {
                return 1;
            } else {
                return 0;
            }
        };
        const sortedCurrencies = currencies
            .map((id) => allCurrencies.filter((elem: any) => elem.id === +id)[0])
            .sort(compare_code);

        return sortedCurrencies.map((elem) => elem.id);
    },
};

export const dataFormatterUtils = {
    getFormattedNumber: (value: number) => new Intl.NumberFormat('en').format(value),

    getFormattedData: (obj: any, useActiveCurrency = true, currencyCode?: string) => {
        let formattedObj = obj;

        if (Array.isArray(obj)) {
            // deep cloning in order to prevent changing incoming object
            formattedObj = JSON.parse(JSON.stringify(obj));

            formattedObj.forEach((item: any) =>
                dataFormatterUtils.formatData(item, useActiveCurrency, currencyCode),
            );
        } else if (obj) {
            dataFormatterUtils.formatData(formattedObj, useActiveCurrency, currencyCode);
        }

        return formattedObj;
    },

    getFormattedDataForCP: (data: any) => {
        const formattedObj = JSON.parse(JSON.stringify(data));

        formattedObj.forEach((item: any) => dataFormatterUtils.formatDataForCP(item));

        return formattedObj;
    },

    formatDataForCP: (item: any) => {
        for (const fieldName in item) {
            if (
                [
                    'roundStartedAt',
                    'roundFailedAt',
                    'created',
                    'statusUpdated',
                    'startedAt',
                ].includes(fieldName)
            ) {
                item[fieldName] = dayjs(item[fieldName]).format('HH:mm:ss - DD/MM/YYYY');
            }
        }
    },

    formatData: (item: any, useDefaultCurrency: boolean, currencyCode?: string) => {
        const currencyData = useDefaultCurrency
            ? currencyUtils.getActiveCurrencyData()
            : currencyCode
              ? currencyUtils.getCurrencyDataByCode(currencyCode)
              : currencyUtils.getCurrencyDataById(item.currencyId);
        const subunit = currencyData && currencyData.subunit;

        for (const fieldName in item) {
            if (
                [
                    'bet',
                    'win',
                    'totalBet',
                    'totalWin',
                    'freeRoundsBet',
                    'freeRoundsWin',
                    'buyFeatureBet',
                    'buyFeatureWin',
                ].indexOf(fieldName) >= 0
            ) {
                item[fieldName] = currencyUtils.getFormattedCurrency(item[fieldName], subunit);
            } else if (
                ['net', 'startBalance', 'balance', 'freeRoundsNet', 'buyFeatureNet'].indexOf(
                    fieldName,
                ) >= 0
            ) {
                item[fieldName] = currencyUtils.getFormattedCurrencyWithComas(
                    item[fieldName],
                    subunit,
                );
            } else if (
                [
                    'spins',
                    'players',
                    'freeRoundsSpins',
                    'buyFeatureSpins',
                    'grantedFreeRounds',
                    'remainedFreeRounds',
                ].indexOf(fieldName) >= 0
            ) {
                item[fieldName] = dataFormatterUtils.getFormattedNumber(item[fieldName]);
            } else if (
                ['rtp', 'freeRoundsRtp', 'buyFeatureRtp'].indexOf(fieldName) >= 0 &&
                item[fieldName]
            ) {
                item[fieldName] = dataFormatterUtils.getFormattedNumber(item[fieldName].toFixed(2));
            } else if (fieldName === 'gameId') {
                item.gameName = OperatorGamesUtils.getGameNameByID(item.gameId);
            } else if (
                [
                    'endTime',
                    'startTime',
                    'roundStartedAt',
                    'roundFailedAt',
                    'startedAt',
                    'startDate',
                    'endDate',
                ].includes(fieldName)
            ) {
                item[fieldName] = dayjs(item[fieldName]).format(CONSTANTS.dateFormatWithTime);
            }
        }
    },
};

export const projectDetection = {
    isCP: () => {
        const authorities = localStorageHelper.getChainedValue('user.authorities');
        let isCPProject = false;

        if (authorities) {
            authorities.forEach((authority: string) => {
                if (CONFIG_PROVIDER_ROLES.includes(authority)) {
                    isCPProject = true;
                }
            });
        }

        return isCPProject;
    },
    isBO: () => !projectDetection.isCP(),
    getProjectName: () =>
        typeof projectDetection.isBO() === 'boolean'
            ? projectDetection.isBO()
                ? 'backOffice'
                : 'configProvider'
            : null,
};

export const browserDetection = {
    isIE: () => {
        const { userAgent } = window.navigator;
        const msie = userAgent.indexOf('MSIE');

        return msie > -1 || !!userAgent.match(/Trident.*rv:11\./);
    },
};

//TODO: add proper types
export const OperatorGamesUtils: {
    gameListCache: any;
    getGames: () => any;
    getGameDataByID: (id: number) => any;
    getGameNameByID: (id: number) => any;
    isGameTypeIsMines: (id: number) => any;
    isGameTypeIsSlot: (id: number) => any;
    getGameIdByName: (name: string) => any;
    gameIdToName: (id: number) => any;
} = {
    gameListCache: null,

    getGames: () => {
        if (OperatorGamesUtils.gameListCache) {
            return OperatorGamesUtils.gameListCache;
        } else {
            const gamesList = compressUtil.decompress(
                localStorageHelper.getChainedValue('gamesList'),
            );

            if (gamesList) {
                OperatorGamesUtils.gameListCache = gamesList;
            }
            return gamesList;
        }
    },

    getGameDataByID: (id: number) =>
        OperatorGamesUtils.getGames().filter((game: any) => game.id === id)[0],

    getGameNameByID: (id: number) =>
        OperatorGamesUtils.getGameDataByID(id) ? OperatorGamesUtils.getGameDataByID(id).name : id,

    isGameTypeIsMines: (id: number) =>
        OperatorGamesUtils.getGames().filter((game: any) => game.id === id)[0].gameType === 'MINES',

    isGameTypeIsSlot: (id: number) =>
        OperatorGamesUtils.getGames().filter((game: any) => game.id === id)[0].gameType === 'SLOT',

    getGameIdByName: (name: string) =>
        OperatorGamesUtils.getGames().filter((game: any) => game.name === name)[0].id,

    gameIdToName: (id: number) => {
        const isDesiredGame = (game: IGameItemData) => game.id?.toString() === id?.toString();

        return OperatorGamesUtils.getGames()?.find(isDesiredGame)?.name;
    },
};

export const getLoginRequestHeader = (type?: string) => {
    const authorization = localStorage.getItem('authorization');
    const header: any = {};

    if (authorization) {
        header['Authorization'] = authorization;
    }

    if (type) {
        header['Content-Type'] = type;
    }

    return header;
};

export const sort = (sortData: ISortData, arrayToSort: any) => {
    const isNumber = (value: any) => !isNaN(value);
    const { keyToSort, sortState } = sortData;

    return sortState
        ? [...arrayToSort].sort((obj1: any, obj2: any) => {
              let a = 0;
              let b = 0;

              if (['fromDate', 'toDate', 'from', 'to'].indexOf(keyToSort) >= 0) {
                  const time1 = dayjs(obj1[keyToSort]);
                  const time2 = dayjs(obj2[keyToSort]);

                  return sortState === 'desc'
                      ? dayjs(time1).isBefore(time2)
                          ? 1
                          : -1
                      : dayjs(time2).isBefore(time1)
                        ? 1
                        : -1;
              } else if (keyToSort === 'endTime' || keyToSort === 'startTime') {
                  const time1 = dayjs(obj1[keyToSort], 'hh:mm:ss DD/MM/YYYY');
                  const time2 = dayjs(obj2[keyToSort], 'hh:mm:ss DD/MM/YYYY');

                  return sortState === 'desc'
                      ? dayjs(time1).isBefore(time2)
                          ? 1
                          : -1
                      : dayjs(time2).isBefore(time1)
                        ? 1
                        : -1;
              } else if (['gameName', 'name', 'code'].indexOf(keyToSort) >= 0) {
                  const a = obj1[keyToSort];
                  const b = obj2[keyToSort];

                  return sortState === 'asc'
                      ? a > b
                          ? 1
                          : a < b
                            ? -1
                            : 0
                      : b > a
                        ? 1
                        : b < a
                          ? -1
                          : 0;
              } else if (keyToSort === 'currencyId') {
                  const currencyData1 = currencyUtils.getCurrencyDataById(obj1[keyToSort]);
                  const currencyData2 = currencyUtils.getCurrencyDataById(obj2[keyToSort]);

                  if (currencyData1 && currencyData2) {
                      const a = currencyData1.code;
                      const b = currencyData2.code;

                      return sortState === 'asc' ? (a > b ? 1 : -1) : b > a ? 1 : -1;
                  }

                  return 0;
              } else if (keyToSort === 'status') {
                  return sortState === 'asc'
                      ? obj1.enabled === obj2.enabled
                          ? 0
                          : obj1.enabled
                            ? 1
                            : -1
                      : obj1.enabled === obj2.enabled
                        ? 0
                        : obj1.enabled
                          ? -1
                          : 1;
              }

              if (isNumber(obj1[keyToSort]) && isNumber(obj2[keyToSort])) {
                  a = obj1[keyToSort];
                  b = obj2[keyToSort];
              } else if (
                  [
                      'bet',
                      'win',
                      'net',
                      'totalBet',
                      'totalWin',
                      'spins',
                      'balance',
                      'startBalance',
                      'endBalance',
                      'rtp',
                      'players',
                  ].indexOf(keyToSort) >= 0
              ) {
                  a = dataFormatToNumbers(obj1[keyToSort]);
                  b = dataFormatToNumbers(obj2[keyToSort]);
              }

              return sortState === 'asc' ? a - b : b - a;
          })
        : [...arrayToSort];
};

export const dataFormatToNumbers = (elem: string, searchKey?: string) =>
    Number(elem && elem.replace(searchKey === 'rtp' ? /[%]/g : /[,]/g, ''));

export const searchByName = (searchString: string, arrayToSort: any) =>
    arrayToSort.filter(
        (player: any) =>
            player.login.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1,
    );

export const getRandomBetween = (max: number, min = 0) =>
    Math.floor(Math.random() * (max - min)) + min;

export const getAbbreviatedValue = (
    initialValue: number,
    currencyCode: string,
    isMoneyValue?: boolean,
    shouldDisplayTooltip?: boolean,
) => {
    if (currencyCode === '') {
        return initialValue;
    }

    const currency = currencyUtils.getCurrencyDataByCode(currencyCode);
    const { subunit } = currency;
    const number = isMoneyValue ? Math.abs(initialValue) / subunit : Math.abs(initialValue);

    const abbreviateNumber = (value: any) => {
        const suffixes = ['', 'K', 'M', 'B', 'T'];
        let newValue = value;
        let suffixNum = 0;

        while (newValue >= 1000) {
            newValue /= 1000;
            suffixNum++;
        }

        return `${initialValue >= 0 ? '' : '-'}${preciseNumber(newValue, suffixNum > 0)}${
            suffixes[suffixNum]
        }`;
    };

    const preciseNumber = (value: any, withAbbreviation = false) => {
        let newValue = value;
        let precisionRate;

        if (newValue === 0) {
            precisionRate = 1;
        } else if (newValue < 1) {
            precisionRate = 2;
        } else if (newValue < 10) {
            precisionRate = 3;
        } else if (newValue < 100) {
            precisionRate = 4;
        } else {
            precisionRate = 5;
        }

        if (isMoneyValue || withAbbreviation) {
            newValue = newValue.toPrecision(precisionRate);
        }

        return newValue;
    };

    return number >= 1000 ? (
        shouldDisplayTooltip ? (
            <Tooltip
                title={
                    isMoneyValue
                        ? currencyUtils.getFormattedCurrencyWithComas(initialValue, subunit)
                        : dataFormatterUtils.getFormattedNumber(initialValue)
                }
            >
                <span>{abbreviateNumber(number)}</span>
            </Tooltip>
        ) : (
            abbreviateNumber(number)
        )
    ) : initialValue >= 0 ? (
        preciseNumber(number)
    ) : (
        -preciseNumber(number)
    );
};

export const getAbbreviateNumber = (value: number, isMoneyValue?: boolean) => {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    let newValue = Math.abs(
        value / (isMoneyValue ? currencyUtils.getActiveCurrencyData().subunit : 1),
    );
    let suffixNum = 0;

    while (newValue >= 1000) {
        newValue /= 1000;
        suffixNum++;
    }

    return (value >= 0 ? 1 : -1) * newValue + '' + suffixes[suffixNum];
};

export const mediaQueryHooksHOC = (Component: any) => {
    const WrappedComponent = (props: any) => {
        const mediaQuery: any = {
            isDesktop: useMediaQuery({ minWidth: MIN_DESKTOP_WIDTH }),
            isTablet: useMediaQuery({
                minWidth: MIN_TABLET_WIDTH,
                maxWidth: MAX_TABLET_WIDTH,
            }),
            isMobile: useMediaQuery({ maxWidth: MAX_MOBILE_WIDTH }),
            isNotMobile: useMediaQuery({ minWidth: MIN_TABLET_WIDTH }),
            portraitOrientation: useMediaQuery({ orientation: 'portrait' }),
            landscapeOrientation: useMediaQuery({ orientation: 'landscape' }),
            isLandscapedMobile: useMediaQuery({
                maxWidth: MAX_LANDSCAPED_MOBILE_WIDTH,
                orientation: 'landscape',
            }),
        };
        return <Component mediaQuery={mediaQuery} {...props} />;
    };

    WrappedComponent.displayName = `WithMediaQueries(${
        Component.displayName || Component.name || 'Component'
    })`;

    return WrappedComponent;
};

export const Messages = {
    success: (str: string, duration: number = CONSTANTS.message_duration) => {
        message.success(str, duration);
    },
    error: (str: string, duration: number = CONSTANTS.message_duration) => {
        message.error(str, duration);
    },
};

export const CPUtils = {
    operatorIdToName: (id: string | undefined) => {
        const operators = localStorageHelper.getChainedValue('user.operators');
        const isDesiredOperator = (operator: IOperatorsData) => operator.id?.toString() === id;

        return operators.find(isDesiredOperator)?.name;
    },

    gameIdToNames: (ids: number[]) => {
        const games =
            localStorageHelper.getChainedValue('user.allGames') || OperatorGamesUtils.getGames();

        return ids.map((id: number) => games.find((game: IGameItemData) => game.id === id)?.name);
    },
};

export const componentShouldBeDisplayed = (component: string) => {
    const role = localStorageHelper.getChainedValue('user.role');
    const EXCLUDED_COMPONENTS_MAP: any = {
        settingsLink: ['INTEGRATOR', 'BILLING_REPORTER', 'L1_SUPPORT'],
        searchField: ['INTEGRATOR', 'BILLING_REPORTER', 'L1_SUPPORT'],
    };

    return !EXCLUDED_COMPONENTS_MAP[component].includes(role);
};

export const sortCPCurrenciesArray = (
    data: ICurrencyData[],
    key: 'id' | 'name' | 'code' | 'frozen' | 'status',
    state: 'asc' | 'desc',
    isAvailableOnTop?: boolean,
) => {
    const available: ICurrencyData[] = [];
    const unavailable: ICurrencyData[] = [];
    const sort = (arr: ICurrencyData[]) =>
        arr.sort((a: ICurrencyData, b: ICurrencyData) => {
            if (a[key]! > b[key]!) {
                return state === 'asc' ? 1 : -1;
            } else {
                if (b[key]! > a[key]!) {
                    return state === 'asc' ? -1 : 1;
                } else {
                    return 0;
                }
            }
        });
    if (isAvailableOnTop) {
        data.forEach((elem: ICurrencyData) => {
            if (!elem.frozen) {
                available.push(elem);
            } else {
                unavailable.push(elem);
            }
        });
        return sort(available).concat(sort(unavailable));
    } else {
        return sort(data);
    }
};

export const passwordUtil = {
    passwordLength: 8,
    symbols: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&?',
    bigLetters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    smallLetters: 'abcdefghijklmnopqrstuvwxyz',
    numbers: '0123456789',
    specialSymbols: '!@#$%&?',

    getRandomSymbol: (symbols: string) =>
        symbols.charAt(Math.floor(Math.random() * symbols.length)),

    getRandomPositions: (amount: number) => {
        const { passwordLength } = passwordUtil;
        const randomPositions: number[] = [];

        while (randomPositions.length < amount) {
            const position = Math.floor(Math.random() * passwordLength);

            randomPositions.indexOf(position) === -1 && randomPositions.push(position);
        }

        return randomPositions;
    },

    generatePassword: () => {
        const {
            passwordLength,
            symbols,
            bigLetters,
            smallLetters,
            numbers,
            specialSymbols,
            getRandomSymbol,
            getRandomPositions,
        } = passwordUtil;
        const symbolGroups = [bigLetters, smallLetters, numbers, specialSymbols];
        const randomPositions = getRandomPositions(symbolGroups.length);
        let generated = '';

        // create random password
        for (let i = 0; i < passwordLength; i++) {
            generated += getRandomSymbol(symbols);
        }

        // add by one symbol from each group in order to make password valid
        const generatedArray = generated.split('');

        randomPositions.forEach(
            (position: number, index: number) =>
                (generatedArray[position] = getRandomSymbol(symbolGroups[index])),
        );

        return generatedArray.join('');
    },

    validatePassword: (password: string) => {
        const { passwordLength } = passwordUtil;

        return (
            !!password &&
            password.length >= passwordLength &&
            password.indexOf(' ') === -1 &&
            /\d/.test(password) &&
            /[a-z]/.test(password) &&
            /[A-Z]/.test(password) &&
            /[!@#$%&?]/.test(password)
        );
    },

    getValidationMessage: (password: string, name: string, email: string) => {
        const { passwordLength } = passwordUtil;
        let validationMessage = '';

        if (!password || password.length < passwordLength) {
            validationMessage = 'password_length_validation';
        } else if (
            !(
                /\d/.test(password) &&
                /[a-z]/.test(password) &&
                /[A-Z]/.test(password) &&
                /[!@#$%&?]/.test(password)
            )
        ) {
            validationMessage = 'password_symbols_validation';
        } else if (password === email || password === name) {
            validationMessage = 'password_user_name_validation';
        }

        return validationMessage;
    },
};

export const compressUtil = {
    compress: (obj: any) => LZString.compressToUTF16(JSON.stringify(obj)),
    decompress: (str: string) => JSON.parse(LZString.decompressFromUTF16(str)),
};
