import * as React from 'react';

export const LeftPart = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="login-page__container login-page__container__red">
            <div className="login-page__logo-container" />
            <div className="login-page__copyright">
                <p className="copyright__text">© 2017-{currentYear} RubyPlay</p>
            </div>
        </div>
    );
};
