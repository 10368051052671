import React from 'react';
import { currencyUtils, localStorageHelper, OperatorGamesUtils } from 'utils';
import { Loader } from 'Components/Loader/Loader';
import { Overlay } from 'Components/Overlay/Overlay';
import * as i18next from 'i18next';
import { CloseCircleOutlined } from '@ant-design/icons/lib';
import { GAME_HISTORY_URL } from '../../../utils/config';

interface IProps {
    data: any;
    summaryData: any;
    currencyId: number;
    onClose: () => void;
    t: i18next.TFunction;
    error: string;
}

interface IState {
    loading: boolean;
}

enum RoundType {
    Paid = 'Paid',
    Bought = 'Bought',
    Free = 'Free',
}

class GameHistory extends React.Component<IProps, IState> {
    iframe: any;

    state: IState = {
        loading: true,
    };

    componentDidMount = () => {
        this.iframe.onload = () => {
            const { summaryData, currencyId, data } = this.props;
            const { gameId } = summaryData;
            const gameHistoryData: any = {
                game: OperatorGamesUtils.getGameDataByID(gameId),
                currency: currencyUtils.getCurrencyDataById(currencyId),
                betSession: data,
                assetsPath: `${GAME_HISTORY_URL}/rp_${gameId}/latest`,
                summaryData,
                bought: summaryData.type === RoundType.Bought,
                bet: summaryData.bet,
            };

            this.iframe.contentWindow.postMessage(JSON.parse(JSON.stringify(gameHistoryData)), '*');

            this.resizeFrame();

            this.setState(() => ({ loading: false }));
        };
    };

    resizeFrame = () => {
        if (this.iframe) {
            setTimeout(() => this.resizeFrame(), 100);
        }
    };

    render = () => {
        const { summaryData, onClose, error, t } = this.props;
        const { loading } = this.state;
        const gameHistoryLanguage =
            localStorageHelper.getChainedValue('user.preferences.gh_language') || 'en';

        return (
            <>
                <div id="gameHistory">
                    {error ? (
                        <div className="error">{error}</div>
                    ) : (
                        <>
                            <iframe
                                title={t('players_report_game_history')}
                                className={loading ? '' : 'ready'}
                                src={`${GAME_HISTORY_URL}/rp_${summaryData.gameId}/latest/index.html?lang=${gameHistoryLanguage}`}
                                ref={(elem) => (this.iframe = elem)}
                            />
                            {loading ? (
                                <Loader />
                            ) : (
                                <CloseCircleOutlined className="closeBtn" onClick={onClose} />
                            )}
                        </>
                    )}
                </div>
                <Overlay isVisible switchState={onClose} />
            </>
        );
    };
}

export default GameHistory;
