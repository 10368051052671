import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import '../commonStyles.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { currencyUtils, dateFormatter, localStorageHelper } from 'utils';
import CasinoConsolidatedDataChart from 'Containers/Charts/CasinoCharts/CasinoConsolidatedDataChart';
import CasinoBrokenDownDataChart from 'Containers/Charts/CasinoCharts/CasinoBrokenDownDataChart';
import { ICasinoPerformanceData } from 'redux/reducers/backoffice/casino-performance-report-reducer';
import { getCasinoPerformanceReport } from 'redux/actions/backoffice/casino-performance-report-actions';
import {
    getCasinoPerformance,
    getCasinoTotalLine,
    getRawCasinoPerformanceData,
} from 'redux/selectors/backoffice/casino-performance-report-selectors';
import { getServerError } from 'redux/selectors/backoffice/error-selectors';
import { withTranslation, WithTranslation } from 'react-i18next';
import { casinoReportData } from '../columnsData';
import { Loader } from '../../../Components/Loader/Loader';
import { HeaderTitleComponent } from '../../../Components/ContentComponent/HeaderTitleComponent';
import { ErrorComponent } from '../../Errors/ErrorComponent';
import TotalLineComponent from '../../../Components/TotalLineComponent/TotalLineComponent';
import { ContentComponent } from '../../../Components/ContentComponent/ContentComponent';
import TableHeaderWrapper from '../../../Components/Table/TableHeaderWrapper';
import { Modal, Button, Empty, Table, Divider, Checkbox, Radio } from 'antd';
import { Link } from 'react-router-dom';
import { Context } from 'App';
import CasinoColumns from './CasinoColumns';
import { SettingOutlined } from '@ant-design/icons';
import { getDateOfLastRefresh } from '../../../redux/selectors/user-selectors';

interface IProps {
    getReport: Function;
    data: ICasinoPerformanceData[];
    rawData: ICasinoPerformanceData[];
    totalLine: any;
    error: string;
    dateOfLastRefresh: Date;
}

interface IProps extends WithTranslation {
    data: ICasinoPerformanceData[];
    totalLine: any;
    error: string;
    getReport: Function;
    dateOfLastRefresh: Date;
}

const PerformanceReportComponent = (props: IProps) => {
    const { t, data, totalLine, error, getReport, dateOfLastRefresh } = props;
    const activeCurrencyCode = currencyUtils.getActiveCurrencyCode();
    const isFiltered =
        localStorageHelper.getChainedValue('user.reports.filters.currencies')?.length > 0;
    const columns = CasinoColumns(activeCurrencyCode, isFiltered);
    const options = columns.map(({ key }) => ({
        label: t(key),
        value: key,
    }));
    const initialDates = localStorageHelper.getChainedValue('user.reports.dates');
    const currenciesBreakdown = localStorageHelper.getChainedValue(
        'user.reports.filters.currenciesBreakdown',
    );

    const [tableSize, setTableSize] = useState<'small' | 'middle' | 'large'>('middle');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [tableHeight, setTableHeight] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [checkedList, setCheckedList] = useState(columns.map((item) => item.key));
    const [fromDate, setFromDate] = useState<Date>(
        initialDates ? initialDates.fromDate : dateFormatter.getDefaultDate(),
    );
    const [toDate, setToDate] = useState<Date>(
        initialDates ? initialDates.toDate : dateFormatter.getDefaultDate(false),
    );
    const visibleColumns = columns.filter((column) => checkedList.includes(column.key));
    const totalWidth = visibleColumns.reduce((total, col) => total + (col.width || 0), 0);

    const calculateTableHeight = useCallback(() => {
        const height = window.innerHeight * 0.6 - 194;
        setTableHeight(height);
    }, []);

    const toggleModal = () => {
        setIsModalVisible(!isModalVisible);
    };

    const getReportData = useCallback(() => {
        const isCurrencyBrokenDown = currenciesBreakdown;
        getReport({ fromDate, toDate, isCurrencyBrokenDown });
    }, [fromDate, toDate, currenciesBreakdown, getReport]);

    const handlePageSizeChange = (current: any, size: any) => {
        setPageSize(size);
    };

    const parentReportCallback = (dates: { fromDate: Date; toDate: Date }) => {
        setFromDate(dates.fromDate);
        setToDate(dates.toDate);
    };

    const reportData = data ? (data.length ? data : []) : null;

    useEffect(() => {
        getReportData();
    }, [getReportData, fromDate, toDate, currenciesBreakdown]);

    useEffect(() => {
        setLoading(false);
    }, [data, error]);

    useEffect(() => {
        calculateTableHeight();
        window.addEventListener('resize', calculateTableHeight);

        return () => window.removeEventListener('resize', calculateTableHeight);
    }, [calculateTableHeight]);

    let chart = null;
    if (!loading) {
        chart = data ? (
            currenciesBreakdown ? (
                <CasinoBrokenDownDataChart data={props.rawData} />
            ) : (
                <CasinoConsolidatedDataChart data={props.rawData} />
            )
        ) : null;
    }

    return (
        <ContentComponent
            customBreadcrumbs={<Link to={`/casino/reports`}>{t('reports')}</Link>}
            header={
                <Context.Consumer>
                    {(context) => (
                        <>
                            <TableHeaderWrapper
                                titleComponent={
                                    <HeaderTitleComponent title={t('casino_performance')} />
                                }
                                parentReportCallback={parentReportCallback}
                                getReport={getReportData}
                                fromDate={fromDate}
                                toDate={toDate}
                                reportKey="casino"
                                context={context}
                                currenciesBreakdown={currenciesBreakdown}
                                dateOfLastRefresh={dateOfLastRefresh}
                            />
                        </>
                    )}
                </Context.Consumer>
            }
            preHeader={
                reportData && reportData.length && !error ? (
                    <TotalLineComponent data={totalLine} columns={casinoReportData} />
                ) : undefined
            }
            innerContent={
                error ? (
                    <ErrorComponent error={error} />
                ) : reportData ? (
                    reportData.length ? (
                        <>
                            {chart}
                            <div className="casino_performance-table-container">
                                <div className={'casino_performance-table-title'}>
                                    <div className="content-wrapper">
                                        <>
                                            <Button
                                                style={{ marginRight: '8px' }}
                                                onClick={toggleModal}
                                            >
                                                <SettingOutlined />
                                            </Button>
                                            {currenciesBreakdown && 'Data broken down by currency'}
                                        </>
                                    </div>
                                    <Modal
                                        title="Table tools"
                                        open={isModalVisible}
                                        onOk={toggleModal}
                                        onCancel={toggleModal}
                                        footer={[
                                            <Button
                                                key="submit"
                                                type="primary"
                                                onClick={toggleModal}
                                            >
                                                OK
                                            </Button>,
                                        ]}
                                    >
                                        <Divider>Columns displayed</Divider>
                                        <Checkbox.Group
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                marginBottom: '20px',
                                            }}
                                            options={options}
                                            value={checkedList}
                                            onChange={(checkedValues) => {
                                                setCheckedList(checkedValues.map(String));
                                            }}
                                        />
                                        <Divider>Columns displayed</Divider>
                                        <div style={{ marginBottom: 16 }}>
                                            <span>Change Table Cell Size: </span>
                                            <Radio.Group
                                                value={tableSize}
                                                onChange={(e) => setTableSize(e.target.value)}
                                            >
                                                <Radio.Button value="large">Large</Radio.Button>
                                                <Radio.Button value="middle">Middle</Radio.Button>
                                                <Radio.Button value="small">Small</Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </Modal>
                                </div>
                                <Table
                                    style={{
                                        minWidth: '100%',
                                        paddingBottom: '16px',
                                    }}
                                    className={'casino_performance-table'}
                                    dataSource={reportData}
                                    scroll={{ x: totalWidth, y: tableHeight }}
                                    columns={visibleColumns}
                                    pagination={{
                                        pageSize,
                                        position: ['topRight'],
                                        pageSizeOptions: [10, 20, 50, 100],
                                        onShowSizeChange: handlePageSizeChange,
                                    }}
                                    size={tableSize}
                                    rowKey={(record) =>
                                        `${record.from.toString()}-${record.to.toString()}-${record.currencyId.toString()}`
                                    }
                                    footer={() => <></>}
                                />
                            </div>
                        </>
                    ) : (
                        <Empty description={t('no_data')} />
                    )
                ) : (
                    <Loader style={{ height: '90vh' }} />
                )
            }
        />
    );
};

const mapStateToProps = (state: any) => ({
    data: getCasinoPerformance(state),
    rawData: getRawCasinoPerformanceData(state),
    totalLine: getCasinoTotalLine(state),
    error: getServerError(state),
    dateOfLastRefresh: getDateOfLastRefresh(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getReport: (dates: any) => dispatch(getCasinoPerformanceReport(dates)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTranslation()(PerformanceReportComponent));
